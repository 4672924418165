import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
// import Image from "gatsby-image";
import styled from "styled-components";
// import { capitalize } from "lodash";

import Layout from "../components/Layout";
// import Timestamp from "../components/Timestamp";

import { HTMLContent } from "../components/Content";
import { ResponsiveWidthContainer, SectionTitle } from "../components/Section";
import { Button } from "../components/Buttons";
import { Rating } from "../components/Rating";
import { InstallerReviewBox } from "../components/CustomerReviewBox";

const BoldUnderline = styled.span`
  font-weight: bold;
  padding-bottom: 5px;
  border-bottom: 2px solid currentColor;
  display: inline-block;
`;

const ResponsiveBox = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-contain: space-between;

  @media all and (min-width: 720px) {
    flex-flow: row nowrap;
  }

  > *:first-child {
    flex-grow: 1;
  }
`;

const Column = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-contain: space-between;
  > * {
    ${props => (props.center ? `margin: 0 auto;` : ``)};
    ${props => (props.left ? `margin-right: auto;` : ``)};
  }
  ${props =>
    props.hideMobile
      ? `display: none; @media (min-width: 720px) { display: flex; }`
      : ``}
`;

const StyledResponsiveWidthContainer = styled(ResponsiveWidthContainer)`
  > section {
    margin-top: 3rem;
  }
`;

// const StyledSectionTitle = styled(SectionTitle)`
//   color: #3d3a3c;
//   margin: 0;
// `;

// const StyledVerticalSocialShareLinks = styled(VerticalSocialShareLinks)`
//   color: #02bbe7;
// `;

const StyledCenter = styled.div`
  text-align: center;
`;

const StyledContentWithTable = styled(HTMLContent)`
  table {
    width: 100%;
    text-align: center;
    tbody tr:nth-child(odd) {
      background-color: #ebebeb;
    }
    tr {
      height: 2.5rem;
    }
    margin: 2rem auto;
  }
`;

export const LocationPageTemplate = ({ place, stateContent, cityContent }) => {
  // const postData = {
  //   title: `${city}, ${state} — Your Solar Quotes`,
  //   siteTitle: "Your Solar Quotes",
  //   href: location.href
  // };

  return (
    <StyledResponsiveWidthContainer>
      <ResponsiveBox>
        <SectionTitle leftAlign>
          Compare the best solar panels in {place.name}, {place.state_code}
        </SectionTitle>
        <Button>Compare 3 Quotes</Button>
      </ResponsiveBox>
      {cityContent && <HTMLContent className="content" content={cityContent} />}
      {cityContent && <br />}
      {stateContent && (
        <StyledContentWithTable className="content" content={stateContent} />
      )}
      {stateContent && <br />}
      <SectionTitle leftAlign>
        Easiest way to compare 3 quotes in {place.name}
      </SectionTitle>
      <p>
        We put you in touch with reliable, Clean Energy Council (CEC) accredited
        installers who will look at your situation and come up with the best
        system for your needs. Just compelte the simple form and you
        {"'"}ll be contacting 3 local solar firms who can help you find the
        system that{"'"}s right for your needs.
      </p>
      <StyledCenter>
        <Button>Get 3 Quotes Now!</Button>
      </StyledCenter>
      <SectionTitle leftAlign>
        Average rating of Solar Companies that service {place.name}
      </SectionTitle>
      {place.tq_ratings && (
        <ResponsiveBox>
          <Column>
            <div
              css={`
                font-size: 2.5rem;
              `}
            >
              <Rating count={place.tq_ratings.overall} />
            </div>
            <div>
              Based on{" "}
              <BoldUnderline>{place.tq_ratings.count} reviews</BoldUnderline>
            </div>
          </Column>
          <Column>
            <ul
              css={`
                list-style-type: none;
                font-size: 2rem;
              `}
            >
              <li>
                <Rating count={place.tq_ratings.industry_expertise} /> Industry
                Experience
              </li>
              <li>
                <Rating count={place.tq_ratings.customer_service} /> Customer
                Service
              </li>
              <li>
                <Rating count={place.tq_ratings.installation} /> Workmanship
              </li>
              <li>
                <Rating count={place.tq_ratings.post_support} />
                After Service Support
              </li>
            </ul>
          </Column>
        </ResponsiveBox>
      )}
      <SectionTitle leftAlign>
        Our recommended Solar Companies that service {place.name}
      </SectionTitle>
      {place.tq_suppliers.map(supplier => (
        <InstallerReviewBox key={supplier.original_id} profile={supplier} />
      ))}
      <SectionTitle leftAlign>
        Browse Solar Companies that service nearby locations {place.name}
      </SectionTitle>
      <p>… locations follow</p>
    </StyledResponsiveWidthContainer>
  );
};

LocationPageTemplate.propTypes = {
  place: PropTypes.object.isRequired,
  stateContent: PropTypes.string,
  cityContent: PropTypes.string
};

const LocationPage = ({ data, location }) => {
  const { place, city, state } = data;

  const cityContent = (city && city.html) || "";
  const stateContent = (state && state.html) || "";

  return (
    <Layout location={location}>
      <LocationPageTemplate
        place={place}
        stateContent={stateContent}
        cityContent={cityContent}
      />
    </Layout>
  );
};

LocationPage.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object
};

export default LocationPage;

export const locationPageQuery = graphql`
  query LocationPage($id: String!, $city_name: String!, $state_code: String!) {
    place: locationsJson(id: { eq: $id }) {
      name
      state_code
      tq_ratings {
        count
        post_support
        industry_expertise
        installation
        customer_service
        overall
      }
      tq_suppliers {
        original_id
        name
        review_count
        rating_overall
        address {
          full
          raw
        }
      }
    }
    state: markdownRemark(frontmatter: { state: { eq: $state_code } }) {
      html
      frontmatter {
        state
        updated
      }
    }
    city: markdownRemark(frontmatter: { city: { eq: $city_name } }) {
      html
    }
    # markdownRemark(id: { eq: $id }) {
    #   html
    #   frontmatter {
    #     name
    #     state
    #     linkedStateFile {
    #       childMarkdownRemark {
    #         html
    #         frontmatter {
    #           state
    #         }
    #       }
    #     }
    #   }
    # }
  }
`;
