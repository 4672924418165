import React from "react";
import PropTypes from "prop-types";
import Image from "gatsby-image";
import { graphql, StaticQuery } from "gatsby";

const VerifiedLogo = ({ className }) => {
  return (
    <StaticQuery
      query={graphql`
        query VerifiedLogoQuery {
          imageSharp(
            fixed: { originalName: { eq: "verified-by-totalquote-logo.png" } }
          ) {
            fixed(width: 140) {
              ...GatsbyImageSharpFixed_noBase64
            }
          }
        }
      `}
      render={data => (
        <Image fixed={data.imageSharp.fixed} className={className} />
      )}
    />
  );
};

VerifiedLogo.propTypes = {
  className: PropTypes.string
};

export default VerifiedLogo;
