import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Link } from "gatsby";

import { Button, ButtonLink } from "../components/Buttons";
import { Rating } from "../components/Rating";
import Timestamp from "../components/Timestamp";
import VerifiedLogo from "../components/VerifiedLogo";
import { FaMapMarkerAlt } from "react-icons/fa";

import { sanitizeString } from "../utils";

const ResponsiveBox = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: center;

  @media all and (min-width: 620px) {
    flex-flow: row nowrap;
  }
`;

const Row = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
`;

// const Column = styled.div`
//   display: flex;
//   flex-flow: column nowrap;
//   justify-contain: space-between;
// `;

export const CustomerReviewBox = ({ review }) => {
  return (
    <div>
      <ResponsiveBox>
        <Rating count={review.review_rating} />
        <Timestamp date={review.review_date} lastUpdatedText={false} />
        <VerifiedLogo />
      </ResponsiveBox>
      <Row>
        {review.review_name} from {review.review_suburb} {review.review_state}
      </Row>
      <ResponsiveBox>
        <p>{review.review_content}</p>
        <Button outline color="basic">
          Read full review
        </Button>
      </ResponsiveBox>
    </div>
  );
};

CustomerReviewBox.propTypes = {
  review: PropTypes.object
};

const iconStyle = `
  font-size: 2rem;
  margin-right: 0.5rem;
  margin-bottom: 1rem;
`;

const BoldUnderline = styled.span`
  font-weight: bold;
  padding-bottom: 5px;
  border-bottom: 2px solid currentColor;
  display: inline-block;
`;

const HorizontalRule = styled.hr`
  border: 0;
  display: block;
  width: 85%;
  background-color: gray;
  height: 3px;
  margin-bottom: 3rem;
`;

const StyledVerifiedLogo = styled(VerifiedLogo)`
  visibility: hidden;
  @media all and (min-width: 620px) {
    visibility: visible;
  }
`;

const StyledInstallerName = styled(Link)`
  font-size: 2rem;
  text-decoration: none;
  color: inherit;
`;

export const InstallerReviewBox = ({ profile }) => {
  const {
    address: { raw, full }
  } = profile;

  return (
    <div>
      <ResponsiveBox>
        <Rating
          count={profile.rating_overall}
          css={`
            font-size: 2rem;
          `}
        />
        <div
          css={`
            font-size: 1.5rem;
          `}
        >
          Based on <BoldUnderline>{profile.review_count} reviews</BoldUnderline>
        </div>
        <StyledVerifiedLogo />
      </ResponsiveBox>
      <Row>
        <StyledInstallerName to={`/reviews/${sanitizeString(profile.name)}`}>
          {profile.name}
        </StyledInstallerName>
      </Row>
      <ResponsiveBox>
        <address
          css={`
            font-size: 1.5rem;
          `}
        >
          <FaMapMarkerAlt css={iconStyle} />
          {full || raw}
        </address>
        <ButtonLink
          outline
          color="basic"
          to={`/reviews/${sanitizeString(profile.name)}`}
        >
          View full profile
        </ButtonLink>
      </ResponsiveBox>
      <HorizontalRule />
    </div>
  );
};

InstallerReviewBox.propTypes = {
  profile: PropTypes.shape({
    name: PropTypes.string,
    rating_overall: PropTypes.number,
    review_count: PropTypes.object,
    address_full: PropTypes.string,
    address_country: PropTypes.string
  })
};

InstallerReviewBox.defaultProps = {
  profile: {
    name: "Company Name",
    rating_overall: 5,
    review_count: 10,
    address_full: "1 Street Rd, Brisbane QLD 1234",
    address_country: "Australia"
  }
};

InstallerReviewBox.propTypes = {
  review: PropTypes.object
};
